import bg from "@/assets/videos/bg/bg.jpg";
import video_mp4 from "@/assets/videos/bg/video.mp4";
import video_webm from "@/assets/videos/bg/video.webm";
import Container from "@/components/ui/Container/Container";
import CallToAction from "@/components/common/CallToAction";
import { useEffect, useState } from "react";
import { updateObjectBasedOnQueryParam } from "@/utils/urlParse";
import { useDataContext } from "@/context/DataContext";
import HeaderOverLayer from "./HeaderOverlayer";
import HeaderBackground from "./HeaderBackground";
const geoSubTitles = {
  dvs: "Контрактные двигатели для автомобилей, спецтехники и коммерческой техники.",
  trans:
    "Контрактные коробки передач для автомобилей, спецтехники и коммерческой техники.",
  turbo: "Турбины для автомобилей, спецтехники и коммерческой техники.",
  gen: "Турбины для автомобилей, спецтехники и коммерческой техники.",
};
const Hero = () => {
  const { data } = useDataContext();
  const [title, setTitle] = useState(
    "Контрактные двигатели и коробки передач для автомобилей, спецтехники и коммерческой техники.",
  );
  useEffect(() => {
    updateObjectBasedOnQueryParam("tt", geoSubTitles, setTitle);
  }, []);
  return (
    <section
      className={
        "py-16 lg:py-32 flex items-center justify-center bg-cover bg-center"
      }
      style={
        {
          // backgroundImage: `url("${bg}")`,
          // minHeight: "calc(100vh - 4rem)",
        }
      }
    >
      <HeaderBackground
        image={bg}
        videos={[{ "video/mp4": video_mp4 }, { "video/webm": video_webm }]}
        poster={bg}
      />
      <HeaderOverLayer />
      <Container className={"z-10 relative"}>
        <div className="text-center">
          <span className="mb-4 inline-block rounded-full py-2 px-4 bg-primary-600">
            Цены ниже рыночных!
          </span>
        </div>
        <h1 className="text-3xl lg:text-4xl text-center font-bold ">{title}</h1>
        <h2 className="text-center font-bold text-2xl mt-5 text-primary-200">
          Гарантия 365 дней
        </h2>
        {data.isRun && (
          <h2 className="text-center font-bold text-2xl mt-5 text-accent-400">
            Доставка по РФ бесплатно!
          </h2>
        )}

        <div className="md:w-1/2 mx-auto w-full text-center">
          <CallToAction
            text={
              <span className={"text-xl"}>
                {data.isRun
                  ? "Напишите нам в WhatsApp и получите скидку 10%"
                  : "Оставьте заявку и получите скидку 10%"}
              </span>
            }
          />
        </div>
      </Container>
    </section>
  );
};
export default Hero;
